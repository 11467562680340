<template>
<div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4">

    <UpdateModule v-if="updateModule" :closeUpdateModulePanel="closeUpdateModulePanel" :moduleId="moduleId" @closeWithUpdate="closeModuleWithUpdate" />
    <UpdateCourse v-if="updateCourse" :closeUpdateCoursePanel="closeUpdateCoursePanel" :courseId="courseId" :selectedModuleId="selectedModuleId" @closeCourseWithUpdate="closeCourseWithUpdate" />
    <DeleteModule v-if="deleteModule" :closeDeleteModulePanel="closeDeleteModulePanel" :moduleId="moduleId" :closeDeleteModulePanelWithdelete="closeDeleteModulePanelWithdelete" />
    <DeleteCourse v-if="deleteCourse" :closeDeleteCoursePanel="closeDeleteCoursePanel" :courseId="courseId" :closeDeleteCoursePanelWithdelete="closeDeleteCoursePanelWithdelete" />

    <div class="flex sm:flex-row flex-col w-full justify-between items-center">
        <el-select class="sm:w-1/3 w-full sm:mb-0 mb-2" @change="loadModuleOfYear" v-model="selectedYear"  placeholder="Année du module">
            <el-option
              v-for="item in years"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
        </el-select>
        <el-select class="sm:w-1/3 w-full " @change="loadCourses" v-model="selectedModuleId" placeholder="Nom du module">
            <el-option
              v-for="item in modules"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
        </el-select>
    </div>




    <div class="flex flex-col h-full w-full" :class="isLoading ? 'hidden' : 'block'">
        <div class="">
            <div class="mb-2">
              <p v-if="selectedModuleId" class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des cours</p>
              <p v-else class="font-semibold text-sm text-gray-800 text-left ml-2 sm:mt-0 mt-5">Liste des modules</p>
            </div>
            <div></div>
        </div>

        <div class="brdr rounded-lg py-6 h-full">
            <div class="mx-4 mb-3">
              <div v-if="selectedYear == null" class="w-full sm:mt-6">
                <el-result
                  icon="info"
                  title="Aucune année séléctionnée"
                  subTitle="Veuillez sélectionner une année ! "
                >
                </el-result>
              </div>

              <div v-else>
                <div v-if="modules.length == 0" class="w-full sm:mt-6">
                  <el-result
                    icon="info"
                    title="Aucun module"
                    subTitle="Il n'existe aucun module pour cette année ! "
                  >
                  </el-result>
                </div>
                <div v-else>
                  <div v-if="selectedModuleId == null" class="w-full rounded-lg bg-white">
                    <div v-for="module in modules" :key="module.value" class="flex justify-between items-center border rounded-md p-2 my-1 mx-8 transition duration-500 transform-gpu hover:scale-105 hover:bg-red-50 hover:border-red-400">
                        <div>
                            {{module.label}}
                        </div>

                        <div class="flex sm:flex-row flex-col justify-center items-center">
                          <div class="flex item-center justify-center">
                              <div @click="(updateModule = true),(moduleId = module.value)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                  </svg>
                              </div>
                              <div @click="(deleteModule = true),(moduleId = module.value)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div v-else-if="selectedModuleId != null"  class="w-full rounded-lg bg-white">
                    <div v-for="course in courses" :key="course.id" class="flex justify-between items-center border rounded-md p-2 my-1 mx-8 transition duration-500 transform-gpu hover:scale-105 hover:bg-red-50 hover:border-red-400">
                        <div>
                            {{course.title}}
                        </div>

                        <div class="flex sm:flex-row flex-col justify-center items-center">
                          <div class="flex item-center justify-center">
                              <div @click="(updateCourse = true) , (courseId = course.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                  </svg>
                              </div>
                              <div @click="(deleteCourse = true) , (courseId = course.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                  </svg>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
                  

    
      
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

</div>

</template>

<script>
import UpdateCourse from '../dashboard/AddModule&Course/UpdateCourse.vue'
import UpdateModule from '../dashboard/AddModule&Course/UpdateModule.vue'
import DeleteModule from '../dashboard/AddModule&Course/DeleteModule.vue'
import DeleteCourse from '../dashboard/AddModule&Course/DeleteCourse.vue'
import { ElMessage } from 'element-plus'
import axiosIns from '../../plugins/axios';

export default {
    components: {
        UpdateCourse,
        UpdateModule,
        DeleteModule,
        DeleteCourse
    },

    data () {
        return {
            isLoading : false,
            updateCourse : false,
            updateModule : false,
            deleteCourse : false,
            deleteModule : false,
            modules: [],
            courses: [],
            selectedCourseId: null,
            selectedYear: null,
            selectedModuleId : null,
            moduleId:null,
            courseId:null,
            years: [
                {
                  value: '1',
                  label: '1 Année',
                },
                {
                  value: '2',
                  label: '2 Année',
                },
                {
                  value: '3',
                  label: '3 Année',
                },
                {
                  value: '4',
                  label: '4 Année',
                },
                {
                  value: '5',
                  label: '5 Année',
                },
                {
                  value: '6',
                  label: '6 Année',
                },
            ],
            

        }
    },

    mounted () {
       
    },

    methods: {
      loadModuleOfYear(){
        this.moduleId = null;
        this.courseId = null;
        this.selectedModuleId = null;
        this.courses = [];
        this.isLoading = true;
        axiosIns.get("/moduleofyear/"+this.selectedYear)
        .then(({ data }) => {
          var mod = data.modules;
          
          let temps = []
          if(data.modules.length != undefined){
            data.modules.forEach(element => {

              const item = {
                label: element.title,
                value: element.id
              }
              temps.push(item);
            });
          }else{
             const item = {
                label: mod[Object.keys(mod)[0]].title,
                value: mod[Object.keys(mod)[0]].id
             }
             temps.push(item);
          }
          
          this.modules = temps;
          this.isLoading = false;

        })
        .catch(() => {
          this.isLoading = false;
        });
      },

      loadCourses() {
        this.moduleId = null;
        this.courseId = null;
        this.selectedCourseId = null;
        this.isLoading = true;
        axiosIns
          .get("/courseofmodule/" + this.selectedModuleId)
          .then(({ data }) => {
            this.courses = data.courses;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
      },

      closeUpdateModulePanel() {
        this.updateModule = false;
      },
      closeModuleWithUpdate(title){
        this.modules.forEach(element => {
            if(element.value === this.moduleId){
                element.label = title;
            }
        });
      },

      closeUpdateCoursePanel() {
        this.updateCourse = false;
      },


      closeCourseWithUpdate(title){
          this.courses.forEach(element => {
            if(element.id === this.courseId){
                element.title = title;
            }
        });
      },

      closeDeleteModulePanel(){
          this.deleteModule = false
      },

      closeDeleteModulePanelWithdelete(){
        this.modules = this.modules.filter((element) => element.value !== this.moduleId);
        this.deleteModule = false
      },


      closeDeleteCoursePanel(){
          this.deleteCourse = false
      },

      closeDeleteCoursePanelWithdelete(){
        this.courses = this.courses.filter((element) => element.id !== this.courseId);
        this.deleteCourse = false
      },


      



    
    },

    computed: {
      isToken() {
        return this.$store.getters.get_token;
      },
    },
    setup() {
  
    const succesUpdate = () => {
      ElMessage({
        message: 'Les données ont été modifiées avec succès.',
        type: 'success',
      })
    }
    const succesDelete = () => {
      ElMessage({
        message: 'Les données ont supprimées avec succès.',
      })
    }
    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }
    return {
      succesUpdate,
      succesDelete,
      errorm
    }
  },
}
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>